import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ManufacturerList from "../manufacturers/manufacturer-list"

const ManufacturerPage = () => (
  <Layout>
    <SEO title="HSCI — Manufacturers" />
    <h1>Trusted Manufacturers</h1>
    <ManufacturerList/>
  </Layout>
)

export default ManufacturerPage
